/* ==|== primary styles =====================================================
   Author: Isaac Durazo
   ========================================================================== */

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #462d1d;
  margin: 0;
}

p {
  font-family: 'Open Sans', sans-serif;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

a.button-style {
  display: inline-block;
  background: #d24551;
  padding: .5em 1em;
  border: none;
  text-transform: uppercase;
  color: white;
  font-size: 1em;
  letter-spacing: 1px;
  margin: 0 0 1em 0;
}

.container {
  max-width: 980px;
  margin: auto;
  overflow: auto;
}

.overlay {
  background: transparent;
  position: relative;
  width: 100%;
  height: 450px; /* iframe height */
  top: 450px;  /* iframe height */
  margin-top: -450px;  /* iframe height */
}

.brown {
  background-color: #462d1d;
}

.yellow {
  background-color: #ffd658;
}

.blue {
  background-color: #82D7DF;
}

.red {
  background-color: #d24551;
}

section .container, header .container {
  overflow: auto;
  padding: 2em 1em;
}

h1, h2, h3, h4 {
  font-family: 'Montserrat', sans-serif;
}

h1, h2 {
  text-transform: uppercase;
}

h1 {
  text-align: center;
  letter-spacing: 2px;
  font-size: 2em;
  margin: 0 0 1.5em 0;
  position: relative;
}

h1:after {
  content: "";
  position: absolute;
  height: 7px;
  width: 100px;
  background: #ca3e53;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -16px;
}

h2 {
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: 3px;
  margin: 0;
}

h3 {
  font-size: 1.5em;
  margin: 0;
}

h4 {
  font-size: 1.3em;
  margin: 0 0 0.5em 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: #d24551;
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
}

p {
  margin-top: 0;
}

.bold {
  font-weight: bold;
}

.gray {
  background-color: #f6f6f6;
}

.nowrap {
  white-space: nowrap;
}


/* ========================
          Header
======================== */

header {
  background-image: url("../img/clouds.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
  margin-top: 3.5em;
}

header .container {
  padding: 2em;
}

.btn {
  background: #d24551;
  padding: .5em 1em;
  border: none;
  text-transform: uppercase;
  color: white;
  font-size: 1em;
  letter-spacing: 1px;
  margin: auto 1em auto 0;
}

.header-details {
  margin-bottom: 4em;
}


/* ========================
    Location / Dates
======================== */

.location-dates {
  text-align: center;
}

.location-dates p {
  font-size: 1.3em;
  line-height: 1.5em;
}

.register {
  display: block;
  margin-bottom: 1em;
}

.venue {
  margin-bottom: 3em;
}

.venue address {
  margin: 0 0 .5em;
}

#location .venue{
  h4, address {
    margin: 0 0 .5em;
  }
  p {
    margin: 0 0 1.5em;
  }
}

#location .venue .image {
  margin-bottom: 1.5em;
  display: block;
}


/* ========================
            Nav
======================== */

nav {
  text-align: center;
  position: relative;
  text-transform: uppercase;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

nav ul {
  display: none;
}

nav ul li {
  border-bottom: 1px solid #a7424f;
  width: 100%;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  border: none;
}

nav a {
  display: block;
  color: white;
  padding: 1.2em 1.8em;
  letter-spacing: 1px;
}

nav a:hover, .active a, .btn:hover, .tito-submit:hover, .button-style:hover {
  background-color: #a7424f;
}

nav input[type=checkbox] {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

nav label {
  display: block;
  min-height: 3.5em;
}

nav label:after {
  background-image: url("../img/menu-more.png");
  content: '';
  position: absolute;
  right: .5em;
  top: .5em;
  font-size: 1.8em;
  width: 23px;
  height: 19px;
}

nav input[type=checkbox]:checked ~ ul {
  display:block;
}

/* ========================
          About
======================== */

#about img {
  float: left;
  margin: 0 2em 1em 0;
  width: 350px;
  padding-top: .5em;
}


/* ========================
          Curriculum
======================== */

#curriculum {
  color: white;
}

#curriculum li {
  text-align: left;
}

.subjects {
  color: white;
  text-align: center;
}

.subjects h2 {
  text-align: center;
  margin-bottom: .5em;
  border-bottom: 4px solid rgba(255, 255, 255, 0.16);
  padding-bottom: .5em;
}


/* ========================
          Register
======================== */

.tito-wrapper {
  background-color: #462d1d;
  padding: 0 1em;
  color: white;
  font-size: 1.5em;
}

.tito-wrapper form {
  overflow: auto;
}

.ticket .header {
  border-bottom: 2px dashed rgba(182, 180, 180,.5);
  padding-bottom: 1em;
}

.tito-submit {
  background: #d24551;
  padding: .5em 1em;
  border: none;
  text-transform: uppercase;
  color: white;
  font-size: 1em;
  letter-spacing: 1px;
  width: 100%;
  cursor: pointer;
}

.tito-badge-link {
  display: none;
}

.tito-ticket-quantity-field {
  width: 2em;
  text-align: center;
}

.tito-ticket em {
  font-style: normal;
}

.tito-ticket-upcoming-explanation, .tito-ticket-upcoming-separator {
  display: none;
}

.tito-ticket-name-wrapper {
  margin-bottom: 0.2em;
}

/* These next two rules can be removed once early bird tickets go on sale */
.tito-ticket-status-upcoming .tito-ticket-upcoming-price:after {
  content: " - On sale: March 3, 2015 at 2pm ET";
}

li.tito-ticket.row {
  border-bottom: 2px solid rgba(255, 255, 255, 0.09);
  padding: .8em 0;
}

li.tito-ticket.row:last-of-type {
  border: none;
}

.tito-ticket-price, .tito-ticket-quantity {
  display: inline-block;
}

.tito-ticket-quantity input {
  border: none;
  font-size: 1em;
  padding: .2em;
}

.tito-ticket-status-upcoming .tito-ticket-name-wrapper, .tito-ticket-status-upcoming .tito-ticket-price-quantity-wrapper {
  opacity: .4;
}

.ticket-details .select_ticket {
  float: right;
}

.ticket .when, .ticket .where {
  display: inline-block;
  margin: .5em .5em 0 0;
}

.policy {
  margin-top: 1em;
}


/* ========================
          Testimonials
======================== */


#testimonials li {
  font-style: italic;
  background-color: white;
  padding: 2em 2em 2em 5em;
  margin-bottom: 1em;
}

#testimonials p { 
  position: relative;
}

q:before {
  content: ' " '; 
  font-size: 5.5em;
  position: absolute;
  color: #d24551;
  font-weight: 600;
  left: -60px;
  top: -27px;
}



/* ========================
          Footer
======================== */

footer {
  padding: 2em 0;
  color: white;
  text-align: center;
}

footer p {
  margin: 0;
}

.bocoup-footer .block {
  padding: 2em;
}

.bocoup-footer .presented-by {
  font-size: 1.2em;
  margin: 0;
}

.bocoup-footer  img {
  max-width: 180px;
  margin-bottom: 0.5em;
  margin-left: .5em;
}

.bocoup-footer p, footer p {
  text-align: center;
}

/* ========================
          TXJS
======================== */

#txjs .txjs-logo{
  float: left;
  width: 150px;
  margin: 0 1em 1em 0;
}

/* ========================
           Blog
======================== */

.blog .content {
  max-width: 760px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.8em;
}

.blog .content h1 {
  margin: 10px 0 7px;

  text-transform: none;
  letter-spacing: 0;
  line-height: 1em;
  text-align: left;
}

.blog .content h1:after {
  width: 0;
  height: 0;
  background: transparent;
}

.blog .content h1:before {
  content: "";
  position: absolute;
  height: 7px;
  width: 100px;
  background: #ca3e53;
  left: 0;
  top: -16px;
}

.blog .content p {
  margin: 0 0 1em 0;
}

.blog .content ul {
  margin: 0 0 1em 1em;

  list-style-type: disc;
}

.blog .content ul ul {
  margin-bottom: 0;
}

.blog .content li {
  
}

.blog .header-details {
  margin-bottom: 0;
}

.blog .header-details img {
  width: 200px;
}

.blog header .container {
  padding: 1em;
}
.blog header {
  background-position-y: -85px;
}

.blog .blog-footer {
  text-align: center;
  font-size: 1.2em;
}

.blog .blog-footer .social * {
  vertical-align: middle;
}

.blog img.right {
  display: block;
  margin: 0 0 1em;
}

/* ========================
        Blog Index
======================== */

.post-list li {
  margin: 2em 0 0;
}

.post-list h2 {
  margin: 0 0 1em;
}

.post-meta {
  font-family: 'Montserrat', sans-serif;
}

.post-excerpt img.right {
  margin: 1em 0;
}


/* ========================
        Blog Posts
======================== */

.blog-post .meta-information {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-size: 1.1em;
  margin-bottom: 40px;
  color: #999;
  position: relative;
}

.blog-post .instructor-post-pic {
  float: left;
  margin-right: 1em;
}

.blog-post h3 {
  margin-bottom: .5em;
}

/* ========================
      Media Queries
======================== */

@media only screen and (min-width: 500px) {

  #curriculum li {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 0 1em 1em 1em;
  }

  #location .venue .image {
    width: 50%;
    float: left;
    margin: 0 1.5em 1.5em 0;
  }

  .register {
    display: inline-block;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 690px) { 

  h1 {
    margin-bottom: 2em;
  }

  section .container, header .container {
    padding: 3em 2em;
  }

  .tito-ticket-name-wrapper {
    float: left;
  }

  .tito-ticket-price-quantity, .tito-ticket-price-quantity-wrapper {
    float: right;
  }

  li.tito-ticket.row {
    overflow: auto;
  }

  .tito-submit {
    width: initial;
    float: right;
  }

  .btn {
    font-size: 1.2em;
  }

  .blog header .container {
    padding: 2em;
  }

  .post-excerpt {
    overflow: auto;
  }

  .blog img.right {
    float: right;
    margin: 0 0 1em 1em;
    max-width: 60%;
  }

  .blog img.right-small {
    max-width: 40%;
  }

}

@media only screen and (min-width: 730px) {  
  
  .tito-wrapper {
    padding: .5em 1.5em;
  }

}

@media only screen and (min-width: 800px) {

  .tito-wrapper {
    padding: 1em 2em;
  }

  #testimonials li {
    width: 48%;
    margin: 1%;
    display: inline-block;
    vertical-align: top;
  }

}

@media only screen and (min-width: 875px) {

  h1 {
    font-size: 2.5em;
  }

  section .container, header .container {
    padding: 4em 3em;
  }

  .location-dates p {
    font-size: 1.7em;
  }

  .blog header .container {
    padding: 3em;
  }


}

@media only screen and (min-width: 900px) { 

  nav ul {
    display: block;
  }

  nav ul li {
    width: initial;
  }

  .btn {
    font-size: 1.2em;
    padding: .8em 1.6em;
  }

  /* Dropdown menu */

  nav label {
    display: none;
    cursor: pointer;
    -webkit-user-select: none;
  }

  #curriculum li {
    width: 33.33%;
  }

}

@media only screen and (min-width: 1280px) { 

  section .container {
    padding: 6em 0;
  }

  section.location-dates .container {
    padding: 3em;
  }

}
